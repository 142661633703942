div.scrollableContent {
    position: relative;
    max-height: 175px;
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
  
    > div.wrapper {
      overflow-y: scroll;
      margin-top: -20px;
      margin-bottom: -20px;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  
    -ms-overflow-style: none;
    scrollbar-width: none;
  
    &::-webkit-scrollbar, > div.wrapper::-webkit-scrollbar {
      display: none;
    }
  
    &::before, &::after {
      content: '';
      z-index: 1;
      position: sticky;
      background: rgb(0, 0, 0);
      left: 0;
      right: 0;
      min-height: 20px;
      height: 20px;
      display: inline-block;
      width: 100%;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }
  
    &:not(.fadeTop)::before, &:not(.fadeBottom)::after {
      opacity: 0;
    }
  
    &.fadeTop::before, &.fadeBottom::after {
      opacity: 1;
    }
  
    &::before {
      top: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%, rgb(0, 0, 0) 100%);
    }
  
    &::after {
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0.4) 100%);
    }
  }
  