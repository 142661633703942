div.interruptedContainer {
    background-color: #000;
    position: fixed;
    inset: 0;
    z-index: 1;
  
    > div {
      transition: opacity 0.5s ease-in-out;
  
      &:not(.visible) {
        opacity: 0;
      }
  
      &.visible {
        opacity: 1;
      }
  
      > h2 {
        margin-top: 40vh;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
      }

      > pre {
        > span:nth-child(2) {
            display: block;
            margin-top: 20px;
        }
      }
    }
  }
  