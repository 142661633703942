@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Roboto+Mono&display=swap');

body {
  font-family: 'Roboto Mono', monospace;
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 0;
  margin: 0 auto 20px;
  text-align: center;
}

div {
  &.pageContent {
    display: inline-block;
    min-width: 360px;
    max-width: 100%;
    padding: 10px 0 0 0;
  }

  &.headerMain {
    h1, h2 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 800;
      margin: 0;
      padding: 0;
    }

    h1 {
      font-size: 3em;
      margin-bottom: 10px;
      letter-spacing: 5px;
    }

    margin-top: 20px;
    line-height: 50px;
    color: white;
  }
}

@media only screen and (min-width: 768px) {
  body {
    max-width: 960px;
    min-width: 768px;
  }
}

@media only screen and (max-width: 768px) {
  body {
    width: 100%;
  }
}
