div.indexerStatus {
  > div.inner {
    position: relative;

    > div {
      &.header {
        text-align: left;
        border-bottom: 2px solid #fff;
        padding-bottom: 10px;
        margin-bottom: 10px;

        > span {
          display: block;
          font-size: 0.8em;
          margin-top: 6px;
          color: #939393;
        }
      }

      &.batchHeader {
        font-size: 0.8em;
        text-align: left;
        margin: 14px 0 10px 0;
      }

      &.batchContainer > div.wrapper > div.batchItem {
        padding: 5px 6px;
        font-size: 0.8em;
        text-align: left;
        background-color: white;
        border-radius: 5px;
        color: #000;
        user-select: none;
        position: relative;
        border: 1px solid transparent;
        height: 15px;
        width: calc(100% - 14px);
        transition: width 0.1s ease-in-out;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &.selected {
          width: calc(100% - 30px);
        }

        > div {
          pointer-events: none;
          position: absolute;
          top: 4px;
          line-height: 15px;

          &:nth-child(1) {
            left: 6px;
          }

          &:nth-child(2) {
            right: 6px;
          }
        }

        &:hover {
          background-color: #000;
          cursor: pointer;
          color: #fff;
          border: 1px solid;
        }
      }

      &.batchSelection > div {
        position: relative;

        &.batchName {
          font-size: 0.8em;
          margin: 8px 0 8px 0;
          text-align: left;
          color: gold;
          border-bottom: 1px solid #151515;
          padding-bottom: 6px;
        }

        &.batchInfo {
          font-size: 0.75em;
          margin-bottom: 10px;
          text-align: left;
          position: relative;

          > span {
            position: absolute;
            top: 0;
            right: 0;
            color: #939393;
          }
        }

        &.batchContent > div.wrapper > div.batchFileItem {
          background-color: #6c24c4;
          border: 1px solid transparent;
          border-radius: 3px;
          padding: 3px 6px 4px 6px;
          text-align: left;
          user-select: none;
          width: calc(100% - 14px);
          transition: width 0.1s ease-in-out;

          &.edged {
            background-color: #7c35d3;
          }

          &:hover {
            cursor: pointer;
            background-color: #fff;
            border: 1px solid transparent;
            color: #000;

            > div.fileDetails {
              color: #fff;

              > div.fileModified, > div.fileSize {
                color: inherit;
              }

              > div.anchorWrapper > a {
                color: inherit;
              }
            }
          }

          &:not(:first-child) {
            margin-top: 6px;
          }

          > div.fileDetails {
            background-color: #000;
            margin-top: 6px;
            border-radius: 4px;
            padding: 6px;
            font-size: 0.75em;
            border: 1px solid #000;
            position: relative;

            > div.anchorWrapper {
              padding-top: 20px;
            }
  
            > div.anchorWrapper > a {
              color: #fff;
              word-break: break-all;
              text-decoration: none;
            }

            > div.anchorWrapper > a:hover {
              text-decoration: underline;
            }
  
            > div.fileSize, > div.fileModified {
              position: absolute;
              top: 3px;
              color: #ddd;
            }
  
            > div.fileSize {
              left: 8px;
            }
  
            > div.fileModified {
              right: 8px;
            }
          }

          > div.fileName {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 0.8em;
          }
        }
      }
    }
  }
}
