div.splashView {
    background-color: #000;
    position: fixed;
    inset: 0;
    user-select: none;
    z-index: 1;
    transition: opacity 0.25s ease-in-out;

    &.invalidate {
      pointer-events: none;
    }
  
    > div {
      transition: opacity 0.5s ease-in-out;

      > span {
        font-size: 0.75em;
      }
  
      &:not(.visible) {
        opacity: 0;
      }
  
      &.visible {
        opacity: 1;
      }
  
      > h2 {
        margin-top: 40vh;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        margin-bottom: 4px;
      }

      > div.versionLabel {
        position: fixed;
        inset: auto 8px 8px auto;
        font-size: 0.7em;
      }
    }
  }
  