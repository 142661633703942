div.ServerStatus {
    margin: 10px 0 10px 0;
    border-bottom: 1px solid #151515;
    padding-bottom: 12px;

    > span.latencyServer {
      font-size: 0.95em;
    }
  
    > span.latencyMean {
      display: block;
      margin: 10px 0 18px 0;
      font-size: 0.8em;
    }

    > span.internalHeader {
      font-size: 0.9em;
      border-top: 1px solid #151515;
      width: 100%;
      display: block;
      padding-top: 10px;
    }

    > div.serverConnections,
    > div.internalPings {
      display: block;
      font-size: 0.8em;
    }

    > div.serverConnections {
      text-align: left;
      border-top: 1px solid #151515;
      padding-top: 10px;

      > div:not(:last-child) {
        margin-bottom: 10px;
      }

      span.isConnected {
        color: #48e66f;
      }

      span.isDisconnected {
        color: #ff4c4c;
      }
    }

    > div.internalPings {
      display: block;
      margin: 12px 0 16px 0;
      font-size: 0.8em;

      > span {
        &:nth-child(1) {
          color: gold;

          &:after {
            content: ' <-> ';
            color: #fff;
          }
        }

        &:nth-child(2) {
          color: #959595;
        }

        &:nth-child(3) {
          color: #9cdaff;

          &:before {
            content: ' <-> ';
            color: #fff;
          }
        }
      }
    }
  }
  